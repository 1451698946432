<template>
  <div class="common-bg-member">
    <div class="w700 common-padding-120">
      <h1>
        修改電郵
      </h1>
      <div class="m-form-item">
        <p>電郵</p>
        <el-input v-model="email"  class="m-input" />
      </div>
      <div class="m-form-item">
        <p>驗證碼</p>
        <div style=" width: 100%;position: relative">
          <div class="yzm" :class="{ 'disabled': isCountdownActive }" @click="sendCode">
            {{ countdownText }}
          </div>
          <el-input v-model="code"  class="m-input" />
        </div>
      </div>

      <div class="m-form-item">
        <p>新電郵</p>
        <el-input  v-model="newEmail" class="m-input" />
      </div>

      <div class="m-btn regi">
        <button class="m-btn-common" @click="confirm">確認</button>
      </div>
    </div>
  </div>

  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>

</template>

<script>
import {changeEmail, sendCode} from "@/network/main";

export default {
  name: "ChangeEmail",
  data(){
    return{
      email:'',
      code:'',
      newEmail:'',
      countdownTimer: null,
      countdownSeconds: 60,
      isCountdownActive: false,
      isPopupVisible: false,
      popupText: '',
    }
  },
  computed: {
    countdownText() {
      return this.isCountdownActive ? `${this.countdownSeconds}s` : '發送驗證碼';
    }
  },
  methods:{
    //发送验证码
    sendCode() {
      this.startCountdown()
      const formData = new FormData();
      formData.append('email', this.email);
      sendCode(formData).then((res)=>{
        if(res.data.code===200){
          this.isPopupVisible = true
          this.popupText="驗證碼已發送！"
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000);
        }else {
          this.isPopupVisible = true;
          this.popupText = res.data.message;
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000);
        }
      }).catch(err=> {
        console.log(err)
      })
    },
    //倒計時
    startCountdown() {
      this.isCountdownActive = true;
      this.countdownTimer = setInterval(() => {
        this.countdownSeconds--;
        if (this.countdownSeconds === 0) {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.countdownTimer);
      this.countdownSeconds = 60;
      this.isCountdownActive = false;
    },

    //提交
    confirm(){
      const formData = new FormData();
      formData.append('email', this.email);
      formData.append('code', this.code);
      formData.append('new_email', this.newEmail);
      changeEmail(formData).then((res)=>{
        if (res.data.code ==200){
          this.isPopupVisible = true;
          this.popupText = "修改成功！";
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000)
          this.$router.push('/member');
        }else {
          this.isPopupVisible = true
          this.popupText = res.data.message
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000)
        }
      })
    },
  }
}
</script>

<style scoped>

</style>